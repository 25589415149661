import './App.css';
import MainPage from './components/MainPage.jsx';
import {Route, Routes } from 'react-router-dom';
import ProductDetail from './components/ProductDetail.jsx';

function App() {
 

  return (
   
    
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/:slug" element={<ProductDetail />} />
      </Routes>
    
  );
}

export default App;
