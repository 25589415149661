import React, {  useState, useEffect } from 'react';

import Logo from '../picture/output-onlinepngtools (1).png';
import './MainPage.css';
import Modal from 'react-modal';
import ChatGPT from '../picture/gpttrainpng.webp';
import NetFlix from '../picture/netflix-transparent-netflix-free-free-png.webp';
import Canva from '../picture/1691829400logo-canva-png.png';
import CapCut from '../picture/1664284918capcut-icon-png.webp';
import TikTok from '../picture/pngimg.com - tiktok_PNG22.png';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import { useNavigate } from 'react-router-dom';
import dividerImg from '../picture/gold-luxury-line-png.webp';
import Typewriter from 'typewriter-effect';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/effect-coverflow'; // import styles for the Coverflow effect

import capCut1 from '../picture/capcut123.png';
import duoLingo from '../picture/duo11.png';
import youTube from '../picture/youtube1.png';
import netFlix from '../picture/netflix11.png';
import chatGpt from '../picture/chatgpt1.png';
import spoTify from '../picture/spotify1.png';
import canVa from '../picture/canva1.png';
import elSa from '../picture/elsa1.png';
import courSera from '../picture/coursera1.png';
import adoBe from '../picture/adobe1.png';
import vieOn from '../picture/ay.png';
import tikTok from '../picture/tiktoc.png';
import ScrollReveal from 'scrollreveal';
import Zalo1 from '../picture/z5673257561264_71a40834f3efbb626e3566d1fad899e0.jpg';
import Zalo2 from '../picture/z5673257602984_068d987b0dcd2a111897e2d3684f4aa6.jpg';
import Zalo3 from '../picture/z5673258688949_4b6fe0ed6adc6673c2311d724ccee89d.jpg';




const MainPage = () => {
  const [isActive, setIsActive] = useState(false);
  const handleJoinGroup = (url) => {
    window.open(url, '_blank');
  };
  const handleUpgrade = (url) => {
    window.open(url, '_blank');
  };
  const handleClick = () => {
    setIsActive(!isActive);
  };


  useEffect(() => {
    const sections = document.querySelectorAll('section');
    const navLinks = document.querySelectorAll('.sidebar ul li a');

    const onScroll = () => {
      let scrollY = window.pageYOffset;

      sections.forEach((section) => {
        const sectionTop = section.offsetTop - 150;
        const sectionHeight = section.offsetHeight;
        const sectionId = section.getAttribute('id');

        if (scrollY > sectionTop && scrollY <= sectionTop + sectionHeight) {
          navLinks.forEach((link) => {
            link.classList.remove('active');
            if (link.getAttribute('href').includes(sectionId)) {
              link.classList.add('active');
            }
          });
        }
      });
    };

    window.addEventListener('scroll', onScroll);

    navLinks.forEach((link) => {
      link.addEventListener('click', (e) => {
        e.preventDefault();
        const targetId = link.getAttribute('href').slice(1);
        const targetSection = document.getElementById(targetId);

        targetSection.scrollIntoView({ behavior: 'smooth' });
      });
    });

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  useEffect(() => {
    // Chọn phần tử bằng ID
    const element = document.getElementById('content-container');
    if (element) {
      // Ghi đè padding
      element.style.padding = '0';
    }
  }, []); // Mảng phụ thuộc rỗng để chạy một lần khi component được mount

  useEffect(() => {
    const circle = document.querySelector('.circle');
    if (circle) {
      let textCircle = circle.innerText.split('');
      circle.innerText = '';
      textCircle.forEach((value, key) => {
        let newSpan = document.createElement('span');
        newSpan.innerText = value;
        let rotateThisSpan = (360 / textCircle.length) * (key + 1);
        newSpan.style.setProperty('--rotate', rotateThisSpan + 'deg');
        circle.appendChild(newSpan);
      });
    }

    const list = document.querySelector('.list');
    const items = document.querySelectorAll('.item');
    const prev = document.getElementById('prev');
    const next = document.getElementById('next');
    let active = 0;
    let count = 5;
    let width_item = items[active].offsetWidth;
    let leftTransform = 0;

    next.onclick = () => {
      active = active >= count - 1 ? count - 1 : active + 1;
      runCarousel();
    };

    prev.onclick = () => {
      active = active <= 0 ? 0 : active - 1;
      runCarousel();
    };

    function runCarousel() {
      prev.style.display = active === 0 ? 'none' : 'block';
      next.style.display = active === count -1 ? 'none' : 'block';
      let old_active = document.querySelector('.item.active');
      if (old_active) old_active.classList.remove('active');
      items[active].classList.add('active');
      leftTransform = width_item * (active - 2) * -1;
      list.style.transform = `translateX(${leftTransform}px)`;
    }

    runCarousel();
  }, []);

  useEffect(() => {
    ScrollReveal().reveal('#home', {
      origin: 'top',
      distance: '50px',
      duration: 1000,
      easing: 'ease-in-out',
      reset: true,
    });
    ScrollReveal().reveal('#shop', {
      origin: 'top',
      distance: '50px',
      duration: 1000,
      easing: 'ease-in-out',
      reset: true,
    });
    ScrollReveal().reveal('#contact', {
      origin: 'top',
      distance: '50px',
      duration: 1000,
      easing: 'ease-in-out',
      reset: true,
    });
  }, []);

  /* PRODUCT */
  const items = [
    {
      id: 'CANVA PRO',
      name: '⭐ Canva ',
      highlightedName: 'PRO',
      highlightColor: '#31d0f0',
      image: canVa,
      description: '', // General description if needed
      options: [
        { duration: '1 năm', price: '109.000 VNĐ', description: ' \n☛  Sử dụng toàn bộ chức năng tại Canva Pro\n☛ Nâng cấp chính chủ\n☛ Bảo Hành 1 Năm' },
        { duration: '2 năm', price: '169.000 VNĐ', description: ' \n☛  Sử dụng toàn bộ chức năng tại Canva Pro\n☛ Nâng cấp chính chủ\n☛ Bảo Hành 2 Năm' },
        { duration: 'Vĩnh Viễn', price: '219.000 VNĐ', description: ' \n☛  Sử dụng toàn bộ chức năng tại Canva Pro\n☛ Nâng cấp chính chủ\n☛ Bảo Hành Trọn Đời' },
        { duration: 'Vĩnh Viễn (VIP)', price: '399.000 VNĐ', description: '☛ Sử dụng toàn bộ chức năng tại Canva Pro\n☛ Nâng cấp chính chủ\n ☛Ổn định CAO – Bảo Hành Trọn Đời' }
      ]
    },
    {
      id: 'CapCut PRO',
      name: ' ⭐ CAPCUT',
      highlightedName: 'PRO',
      image: capCut1,
      options: [
        { duration: ' 1 năm / Cấp TK / MK', price: '599.000 VNĐ', description:'☛ Mọi thiết kế và dự án của bạn đều riêng tư\n☛ Tài khoản có thời hạn 1 năm, cam kết hoàn tiền nếu có lỗi\n☛ Cam kết là nơi bán tài khoản CapCut Pro rẻ nhất Việt Nam\n☛ Sử dụng đầy đủ 100% tính năng trên CapCut Pro'  },
        { duration: '1 năm / Nâng cấp Chính Chủ ', price: '899.000 VNĐ', description: '☛ Mọi thiết kế và dự án của bạn đều riêng tư\n☛ Tài khoản có thời hạn 1 năm, cam kết hoàn tiền nếu có lỗi\n☛ Cam kết là nơi bán tài khoản CapCut Pro rẻ nhất Việt Nam\n☛ Sử dụng đầy đủ 100% tính năng trên CapCut Pro' },

      ],
      highlightColor: '#ff6347' // Example color
    },
    {
      id: 'YOUTUBE PREMIUM',
      name: '⭐ Youtube ',
      highlightedName: 'Premium',
      highlightColor: '#4f5d5f',
      image: youTube,
      options: [
        { duration: '1 năm', price: '295.000 VNĐ', description: '☛ Xem video không quảng cáo\n☛ Sử dụng cho nhiều thiết bị\n☛ Nâng cấp từ chính email của bạn' },
        { duration: '2 năm', price: '550.000 VNĐ', description: '☛ Xem video không quảng cáo\n☛ Sử dụng cho nhiều thiết bị\n☛ Nâng cấp từ chính email của bạn' }
      ]
    },
    {
      id: 'DUOLINGO',
      name: '⭐ Duolingo ',
      highlightedName: 'Super',
      highlightColor: '#33b23f',
      image: duoLingo,
      options: [
        { duration: '1 năm', price: '279.000 VNĐ', description: '☛ Bạn có thể thay đổi mật khẩu sau khi được nâng cấp\n☛ Có thể chia sẻ tài khoản cho người khác\n☛ Sử dụng trên nhiều thiết bị\n☛ Khả dụng trên: Mobile/Tablets, Máy tính.\n☛ Sử dụng như tài khoản riêng tư, full mọi quyền thao tác' }
      ]
    },
    {
      id: 'NETFLIX',
      name: '⭐ Netflix',
      highlightedName: 'Riêng Tư',
      highlightColor: '#de281f',
      image: netFlix,
      options: [
        { duration: '1 tháng', price: '79.000 VNĐ', description: '☛ Tài khoản sẽ được chia làm nhiều User, mỗi khách hàng sẽ được cấp 1 User riêng trong tài khoản để sử dụng.\n☛ Lưu ý chỉ sử dụng đúng user mang tên mình và không được đổi pass trong quá trình sử dụng.\n☛ Khách hàng có thể đăng nhập trên nhiều thiết bị nhưng chỉ được sử dụng 1 thiết bị tại 1 thời điểm (Không xem phim trên nhiều thiết bị cùng lúc).\n☛ Đăng nhập và xem phim (Lưu ý: Tài khoản không hỗ trợ sử dụng trên máy chiếu)\n☛ Hệ thống có thể sẽ thay đổi mật khẩu của bạn vì lý do bảo mật.' }
      ]
    },
    {
      id: 'CHATGPT',
      name: '⭐ ChatGPT',
      highlightColor: '#3ec586',
      highlightedName: '4.0',
      image: chatGpt,
      options: [
        { duration: '3.5 (Vĩnh Viễn)', price: '49.000 VNĐ', description: ''},
        { duration: '4.0 Plus (1 tháng)', price: '179.000 VNĐ', description: '☛ Bạn có thể sử dùng ChatGPT 4 bất cứ lúc nào mà không bị giới hạn 3h hoặc bị hết giói hạn mà bạn chưa sử dụng như các tài khoản chia sẻ bán trên thị trường\n☛ Truy cập chung vào nhiều tài khoản Chat GPT 4 Plus, Chat GPT 4o (Chat GPT 4 Omni)\n☛ Dall-E 3: Tạo ảnh từ văn bản, sáng tạo ra các bức ảnh tuyệt đẹp theo miêu tả của bạn.\n☛ Tạo video AI từ văn bản theo ý tưởng của bạn\n☛ Up ảnh: Hiểu hình ảnh bạn tải lên\n☛ Advanced Data Analysis: Đọc, hiểu file dữ liệu tải lên\n☛ Có thể sử dụng tính năng “Đoạn chat tạm thời” để không lưu lại lịch sử chat với người khác.\n☛ Không API và được cập nhật dữ liệu mới nhất\n☛ Cam kết luôn sử dụng được 80 tin GPT-4, GPT-4o mỗi ngày\n☛ Bảo hành trọn thời gian sử dụng' }
      ]
    },
    {
      id: 'SPOTIFY PREMIUM',
      name: '⭐ Spotify ',
      highlightColor: '#29cd36',
      highlightedName: 'Premium',
      image: spoTify,
      options: [
        { duration: '1 năm', price: '229.000 VNĐ', description: '☛ Sử dụng trên tất cả thiết bị có hỗ trợ Spotify, sử dụng đồng thời chỉ trên 01 thiết bị\n☛ Tài khoản của bạn phải đang là gói Miễn phí, trường hợp tài khoản bạn còn gói Premium thì bạn cần huỷ gia hạn và đợi hết Premium.\n☛ Tài khoản của bạn không tham gia quá 2 family trong vòng 12 tháng gần nhất.\n☛ Nếu trường hợp tài khoản bạn đang đăng nhập bằng SĐT, Facebook, Google, Apple ID thì vẫn nâng cấp được nha' },
        { duration: '2 năm', price: '359.000 VNĐ', description: '☛ Sử dụng trên tất cả thiết bị có hỗ trợ Spotify, sử dụng đồng thời chỉ trên 01 thiết bị\n☛ Tài khoản của bạn phải đang là gói Miễn phí, trường hợp tài khoản bạn còn gói Premium thì bạn cần huỷ gia hạn và đợi hết Premium.\n☛ Tài khoản của bạn không tham gia quá 2 family trong vòng 12 tháng gần nhất.\n☛ Nếu trường hợp tài khoản bạn đang đăng nhập bằng SĐT, Facebook, Google, Apple ID thì vẫn nâng cấp được nha' },
        { duration: '3 năm', price: '529.000 VNĐ', description: '☛ Sử dụng trên tất cả thiết bị có hỗ trợ Spotify, sử dụng đồng thời chỉ trên 01 thiết bị\n☛ Tài khoản của bạn phải đang là gói Miễn phí, trường hợp tài khoản bạn còn gói Premium thì bạn cần huỷ gia hạn và đợi hết Premium.\n•☛Tài khoản của bạn không tham gia quá 2 family trong vòng 12 tháng gần nhất.\n☛ Nếu trường hợp tài khoản bạn đang đăng nhập bằng SĐT, Facebook, Google, Apple ID thì vẫn nâng cấp được nha' }
      ]
    },
    {
      id: 'ELSA',
      name: '⭐ Elsa ',
      highlightColor: '#e844db',
      highlightedName: 'Chính Chủ',
      image: elSa,
      options: [
        { duration: 'Pro (1 năm)', price: '459.000 VNĐ', description: '' },
        { duration: 'Premium (1 năm)', price: '899.000 VNĐ', description: '' }
      ]
    },
    {
      id: 'COURSERA',
      name: '⭐ Cousera',
      highlightColor: '#4478e8',
      highlightedName: 'Chính Chủ',
      image: courSera,
      options: [
        { duration: 'Plus (1 năm)', price: '799.000 VNĐ', description: '☛ Nền tảng học kiến thức chuyên sâu từ các trường đại học hàng đầu.\n☛ Bảo hành 1 đổi 1 nhanh chóng khi có lỗi.\n☛ Nhận chứng chỉ mang tên mình sau khi tốt nghiệp.\n– Nhiều loại Tài khoản để bạn lựa chọn' }
      ]
    },
    {
      id: 'ADOBE FULL',
      name: '⭐ Adobe Full App',
      image: adoBe,
      options: [
        { duration: '1 năm / Cấp Tk/Mk ( 100GB-1TB ) ', price: '789.000 VNĐ', description: '☛ Tài khoản tạo sẵn sẽ do Shop cung cấp tài khoản và mật khẩu đăng nhập. Tài khoản chính chủ sẽ được nâng cấp từ email của bạn\n☛ Bạn có thể sử dụng được tất cả các phần mềm hiện có của Adobe\n☛ Bạn sẽ được sử dụng 1000 lần/tháng cho tính năng Generative Fill\n☛ Sau khi đăng ký mua hàng thành công. Bạn hãy liên hệ và cung cấp cho shop tài khoản, mật khẩu Adobe để được tiến hành nâng cấp nhé.' },
        { duration: '1 năm / Nâng Cấp Chính Chủ ( 100GB-1TB )', price: '999.000 VNĐ', description: '☛ Tài khoản tạo sẵn sẽ do Shop cung cấp tài khoản và mật khẩu đăng nhập. Tài khoản chính chủ sẽ được nâng cấp từ email của bạn\n☛ Bạn có thể sử dụng được tất cả các phần mềm hiện có của Adobe\n☛ Bạn sẽ được sử dụng 1000 lần/tháng cho tính năng Generative Fill\n☛ Sau khi đăng ký mua hàng thành công. Bạn hãy liên hệ và cung cấp cho shop tài khoản, mật khẩu Adobe để được tiến hành nâng cấp nhé.' }
      ]
    },
    {
      id: 'VIEON',
      name: '⭐ Vieon',
      highlightColor: '#21c3d9',
      highlightedName: 'Chính Chủ',
      image: vieOn,
      options: [
        { duration: 'Vip (6 tháng)', price: '259.000 VNĐ', description: '' },
        { duration: 'Full (1 năm)', price: '1.399.000 VNĐ', description: '' }
      ]
    },
    {
      id: 'TIKTOK',
      name: '⭐ Tài khoản',
      highlightColor: '#5f6768',
      highlightedName: 'TikTok',
      image: tikTok,
      options: [
        { duration: '1k Fl - TikTok Shop - LiveStream (Gốc)', price: '90.000 VNĐ', description: '☛ Tài khoản chính chủ có 1000 follow\n☛ Bật tiktok shop\n☛ Bật livestream' }
      ]
    }
  ];
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedItemState, setSelectedItemState] = useState(null);
  const [selectedPrice, setSelectedPrice] = useState('');
  const [selectedOption, setSelectedOption] = useState(null);
  
  const navigate = useNavigate();

  const openModal = (item) => {
    setSelectedItemState(item);
    setSelectedPrice(''); // Reset the selectedPrice state when a new item is selected
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedItemState(null);
    setSelectedPrice(''); // Reset the selectedPrice state when the modal is closed
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setSelectedPrice(`Giá của ${option.duration} là: ${option.price}`);
  };
  const handleViewDetailClick = () => {
    if (selectedItemState) {
      const slug = selectedItemState.id.toLowerCase().replace(/\s+/g, '');
      navigate(`/${slug}`, { state: { product: selectedItemState } });
    }
  };

  return (
    <div className="page-container">
      <div className={`sidebar ${isActive ? 'active' : ''}`}>
        <div className="top">
          <div className="logo">
            <img src={Logo} alt="" className='w-[50px]' />
            <span className='text-xs font-bold'>ONGTRUMTOICHOI</span>
          </div>
          <i className="fa-solid fa-list" onClick={handleClick} id='btn'></i>
        </div>
        <ul>
          <li>
            <a href="#home" >
              <i className="fa-solid fa-house"></i>
              <span className='nav-items text-xs'>Trang chủ</span>
            </a>
            <span className="tooltip text-xs">Trang chủ</span>
          </li>
          <li>
            <a href="#shop">
              <i className="fa-solid fa-cart-shopping"></i>
              <span className='nav-items text-xs'>Dịch vụ</span>
            </a>
            <span className="tooltip text-xs">Dịch vụ</span>
          </li>
          <li>
            <a href="#contact">
              <i className="fa-solid fa-phone"></i>
              <span className='nav-items text-xs'>Liên hệ</span>
            </a>
            <span className="tooltip text-xs">Liên hệ</span>
          </li>
        </ul>
      </div>

      <div className="main-content">
        <div className="container-box">
          <section id='home' className='slider'>
            <div className="list">
              <div className="item">
                <img src={Canva} alt="" />
              </div>
              <div className="item">
                <img src={ChatGPT} alt="" />
              </div>
              <div className="item">
                <img src={CapCut} alt="" />
              </div>
              <div className="item">
                <img src={NetFlix} alt="" />
              </div>
              <div className="item">
                <img src={TikTok} alt="" />
              </div>
            </div>
            <div className="circle">
              DỊCH VỤ BÁN CHẠY TẠI ONGTRUMTOICHOI UY TÍN TẠO THƯƠNG HIỆU
            </div>
            <div className="content">
              <div className="font-bold text-orange-400">DỊCH VỤ</div>
              <div className="OTTC">ONGTRUMTOICHOI</div>
            </div>
            <div className="arrow">
              <button id='prev' className='font-bold'>←</button>
              <button id='next' className='font-bold'>→</button>
            </div>
          </section>

          <div className="divider">
            <img src={dividerImg} alt="" />
          </div>

          <section id='shop'>
            <h1 className='text-3xl font-bold text-center '>
              <span className='inline'>CÁC DỊCH VỤ:
                <span className="text-orange-400 ">
                  <Typewriter
                    options={{
                      strings: ['CHATGPT', 'CANVA PRO', 'SPOTIFY', 'NETFLIX', 'YOUTUBE PREMIUM'],
                      autoStart: true,
                      loop: true,
                    }}
                  />
                </span>
              </span>
            </h1>

            <Swiper
              modules={[Navigation, Pagination]}
              navigation
              pagination={{ clickable: true }}
              spaceBetween={20}
              slidesPerView={3}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                  spaceBetween: 10
                },
                395: {
                  slidesPerView: 1,
                  spaceBetween: 10
                },
                740: {
                  slidesPerView: 1,
                  spaceBetween: 10
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 20
                }
              }}
            >
              {items.map((item) => (
                <SwiperSlide key={item.id}>
                  <div className="item-card" onClick={() => openModal(item)}>
                    <img src={item.image} alt={item.id} className='animate-bounce' />
                    <h3 className='product-name text-lg font-bold mt-[10px]'>
                      {item.name} <span style={{ color: item.highlightColor }}>{item.highlightedName}</span>
                    </h3>
                    <div className="product-options">
                      {item.options.map((option, index) => (
                        <div key={index} className="option-box">{option.duration}</div>
                      ))}
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </section>

          {/* Div trang trí để ngăn cách hai section */}
          <div className="divider">
            <img src={dividerImg} alt="" />
          </div>

          <section id='contact' className='introSec'>
            <div className="items-container">
              <div className="intro-layout">
                <div className="logo-container">
                  <img src={Logo} alt="" className='w-[100px] h-[100px] border-solid border-[1px] border-[white] rounded-[50%] m-auto' />
                </div>
                <h3 className='font-bold text-center mt-[10px]'>Ongtrumtoichoi Shop - Bản Quyền</h3>
                <p className='text-orange-400 text-xs text-center mb-[20px] mt-[10px]'>NÂNG CẤP TRƯỚC - THANH TOÁN SAU</p>
              </div>
            </div>

            <div className="ZaloSec">
        <div className="containers">
          <h1 className='zalo-header'>Zalo Official Ongtrumtoichoi Shop</h1>
          <p className='zalo-subheader'>Hiện tại chỉ có 3 chi nhánh Zalo</p>
          <div className="cards-container">
            <div className="card" style={{ backgroundImage: `url(${Zalo1})` }}>
              <div className="content">
                <h2>03</h2>
                <h3>Zalo Chi Nhánh 3</h3>
                <p>268 Thành Viên</p>
                <button className="button-blue" onClick={() => handleJoinGroup('https://zalo.me/g/skzvtv378')}>Vào Nhóm</button>
              </div>
            </div>
            <div className="card" style={{ backgroundImage: `url(${Zalo2})` }}>
              <div className="content">
                <h2>02</h2>
                <h3>Zalo Chi Nhánh 2</h3>
                <p className='text-red-400'>1000 Thành Viên ( Full )</p>
                <button className="button-pink" onClick={() => handleJoinGroup('https://zalo.me/g/owjycn967')}>Vào Nhóm</button>
              </div>
            </div>
            <div className="card" style={{ backgroundImage: `url(${Zalo3})` }}>
              <div className="content">
                <h2>01</h2>
                <h3>Zalo Chi Nhánh 1</h3>
                <p className='text-red-400'>1000 Thành Viên ( Full )</p>
                <button className="button-green" onClick={() => handleJoinGroup('https://zalo.me/g/hzgabm759')}>Vào Nhóm</button>
              </div>
            </div>
          </div>
          <button id='ZALOCONNECT' onClick={() => handleUpgrade('https://zalo.me/0965927951')} className="explore-button">
            <span>Liên hệ để nâng cấp</span> <i className="fa-solid fa-square-arrow-up-right icon"></i>
          </button>
        </div>
      </div>
          </section>

          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            contentLabel="Item Details"
            className="modal"
            overlayClassName="overlay"
          >
            {selectedItemState && (
              <div>
                <button className="close-button" onClick={closeModal}>X</button>
                <img src={selectedItemState.image} alt={selectedItemState.id} />
                <h3 className='product-name text-lg font-bold mt-[10px]'>
                  {selectedItemState.name} <span style={{ color: selectedItemState.highlightColor }}>{selectedItemState.highlightedName}</span>
                </h3>
                <div className="product-options">
                {selectedItemState.options.map((option, index) => (
              <button
                key={index}
                className={`option-button ${selectedOption && selectedOption.duration === option.duration ? 'active' : ''}`}
                onClick={() => handleOptionClick(option)}
              >
                {option.duration}
              </button>
            ))}
                </div>
                {selectedPrice && <p className="selected-price">{selectedPrice}</p>}
                <button className="upgrade-button" onClick={handleViewDetailClick}>Xem Chi Tiết</button>
              </div>
            )}
          </Modal>
            
        </div>
      </div>
    </div>
  );
};

export default MainPage;
