import React, { useState } from 'react';
import './ProductDetail.css';
import Modal from 'react-modal';
import { useLocation, useNavigate   } from 'react-router-dom';
Modal.setAppElement('#root'); // Make sure your app root element is `#root`
const ProductDetail = () => {
  const location = useLocation();
  const navigate = useNavigate ();
  const { product } = location.state || {};

  const [selectedOption, setSelectedOption] = useState(product?.options[0] || {});

 

  const handleOptionChange = (event) => {
    const selected = product.options.find(option => option.duration === event.target.value);
    setSelectedOption(selected);
  };
  // Hàm để thay thế ký tự xuống dòng bằng thẻ <br>
  const formatDescription = (description) => {
    return description.replace(/☛/g, '<span class="red-icon">☛</span>').replace(/\n/g, '<br>');
  };
  const handleGoBack = () => {
    navigate(-1);
  };
  const handleUpgrade = (url) => {
    window.open(url, '_blank');
  };

  return (
    <div className="product-detail text-white">
      <main>
      <button onClick={handleGoBack} className="go-back-button">← Quay về</button>
        <div className="product-image text-white">
          <img src={product.image} alt={product.name} />
        </div>
        <div className="product-info  bg-[#1c1c1c] text-white">
          <h1 className='text-center mt-[20px]'> {product.name}</h1>
          <p className="price text-white ml-[30px]">- GIÁ: <span className="price-value">{selectedOption.price}</span></p>
          <p className="highlight text-white ml-[30px]">- GÓI: <span className="highlighted-value">{product.highlightedName}</span></p>
          <p className='text-[1.5rem] leading-tight ml-[30px]'>- QUYỀN LỢI SAU KHI NÂNG CẤP: 
          <div className="description text-white" dangerouslySetInnerHTML={{ __html: formatDescription(selectedOption.description) }}></div>
          </p>
          <form className="purchase-form text-white ml-[30px] mr-[30px]">
            <label htmlFor="option">Chọn gói muốn nâng cấp: </label>
            <select id="option" onChange={handleOptionChange}>
              {product.options.map(option => (
                <option key={option.duration} value={option.duration}>
                  {option.duration}
                </option>
              ))}
            </select>
          </form>
            <button className='ButtonUp' onClick={() => handleUpgrade('https://zalo.me/0965927951')}>Nâng Cấp</button>
        </div>
      </main>
    </div>
  );
};

export default ProductDetail;
